exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-clients-index-jsx": () => import("./../../../src/pages/clients/index.jsx" /* webpackChunkName: "component---src-pages-clients-index-jsx" */),
  "component---src-pages-cookies-index-jsx": () => import("./../../../src/pages/cookies/index.jsx" /* webpackChunkName: "component---src-pages-cookies-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-methods-index-jsx": () => import("./../../../src/pages/methods/index.jsx" /* webpackChunkName: "component---src-pages-methods-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-services-business-model-index-jsx": () => import("./../../../src/pages/services/business-model/index.jsx" /* webpackChunkName: "component---src-pages-services-business-model-index-jsx" */),
  "component---src-pages-services-data-coordination-index-jsx": () => import("./../../../src/pages/services/data-coordination/index.jsx" /* webpackChunkName: "component---src-pages-services-data-coordination-index-jsx" */),
  "component---src-pages-services-data-science-index-jsx": () => import("./../../../src/pages/services/data-science/index.jsx" /* webpackChunkName: "component---src-pages-services-data-science-index-jsx" */),
  "component---src-pages-services-funding-index-jsx": () => import("./../../../src/pages/services/funding/index.jsx" /* webpackChunkName: "component---src-pages-services-funding-index-jsx" */),
  "component---src-pages-services-irb-index-jsx": () => import("./../../../src/pages/services/irb/index.jsx" /* webpackChunkName: "component---src-pages-services-irb-index-jsx" */),
  "component---src-pages-services-project-management-index-jsx": () => import("./../../../src/pages/services/project-management/index.jsx" /* webpackChunkName: "component---src-pages-services-project-management-index-jsx" */),
  "component---src-pages-services-workflow-index-jsx": () => import("./../../../src/pages/services/workflow/index.jsx" /* webpackChunkName: "component---src-pages-services-workflow-index-jsx" */),
  "component---src-pages-terms-index-jsx": () => import("./../../../src/pages/terms/index.jsx" /* webpackChunkName: "component---src-pages-terms-index-jsx" */)
}

